import { useTranslation } from 'react-i18next'
import { FETCHING_STATUSES } from 'config/api'
import { AVAILABLE_COLLECTIONS_URL } from 'config/routes'
import { useFetchMyCollectionsWithStoreAndBusinessInfo } from 'hooks/collections'
import NoCollectionIcon from 'images/Tomato.svg'
import Button from 'components/common/Button'
import InfoItem, { infoItemTypes } from 'components/common/InfoItem'
import LoadingSpinner from 'components/common/LoadingSpinner'
import HelpfulInformation from './HelpfulInformation'
import IdCard from './IdCard'
import MyCollectionCard from './MyCollectionCard'
import styles from './MyCollectionCardList.module.scss'

const { IDLE, LOADING, SUCCESS } = FETCHING_STATUSES
const { EMPTY } = infoItemTypes

const MyCollectionCardList = () => {
  const { t } = useTranslation()
  const { sortedCollectionsWithStoreInfo, fetchingStatus: collectionsFetchingStatus } =
    useFetchMyCollectionsWithStoreAndBusinessInfo()

  const hasNoCollections =
    collectionsFetchingStatus === SUCCESS && sortedCollectionsWithStoreInfo.length === 0

  if (collectionsFetchingStatus === IDLE || collectionsFetchingStatus === LOADING) {
    return <LoadingSpinner containerHeight="400px" />
  }

  return (
    <>
      <div>
        {hasNoCollections === true && (
          <div id="no-collections">
            <InfoItem
              type={EMPTY}
              title={t('collection_slots.my-collections.title')}
              description1={t('collection_slots.my-collections.description1')}
              description2={t('collection_slots.my-collections.description2')}
              icon={NoCollectionIcon}
            />
            <Button href={`${AVAILABLE_COLLECTIONS_URL}`}>
              {t('join.food-waste-hero.success-action-button')}
            </Button>
          </div>
        )}
        <div className={styles.idCardContainer}>
          <IdCard />
        </div>
        {hasNoCollections === false && (
          <div
            id="your-collections"
            className={styles.yourCollections}
            data-testid="collection-card-list"
          >
            {sortedCollectionsWithStoreInfo.map((collection) => {
              if (
                collection.business?.logo === undefined ||
                collection.store?.location === undefined
              ) {
                return null
              }

              return (
                <MyCollectionCard
                  key={collection.collectionId}
                  collectionId={Number(collection.collectionId)}
                />
              )
            })}
          </div>
        )}

        <HelpfulInformation />
      </div>
    </>
  )
}

export default MyCollectionCardList
