import { useTranslation } from 'react-i18next'
import { MdOutlineBrandingWatermark } from 'react-icons/md'
import clsx from 'clsx'
import { useAppSelector } from '@redux/hooks'
import MyAvatar from 'components/common/MyAvatar'
import styles from './IdCard.module.scss'

const IdCard = () => {
  const { t } = useTranslation()
  const userName = useAppSelector((state) => {
    const firstName = state.users.currentUser?.name?.firstName
    const lastName = state.users.currentUser?.name?.lastName
    return [firstName, lastName].join(' ')
  })
  const userId = useAppSelector((state) => state.users.currentUser?.id)

  return (
    <div className={clsx('card', styles.root)}>
      <div className={styles.header}>
        <h2>{t('your-collections.id-card.header-text')}</h2>
        <MdOutlineBrandingWatermark className={styles.headerIcon} />
      </div>
      <div>
        <div className={styles.content}>
          <MyAvatar className={styles.avatar} />
          <div className={styles.userDetails}>
            <h3>{userName}</h3>
            <strong>{t('your-collections.id-card.id', { userId })}</strong>
            <strong>{t('your-collections.id-card.title')}</strong>
            <p className={styles.explanationText}>
              {t('your-collections.id-card.please-show-to-staff')}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IdCard
