export const CANCELED_ERROR = 'CanceledError'

export const JOIN_ERROR_SEPARATOR = ', '
export const translateApiErrors = ({ t, errors }) => {
  if (errors === undefined) {
    return undefined
  }

  const errorMap = {
    collections_global_maximum_exceeded: 'api-errors.collections-global-maximum-exceeded',
    collection_donation_taken: 'api-errors.collection-donation-taken',
    collection_end_generic_failure: 'api-errors.collection-end-generic-failure',
    collection_next_collection_too_soon: 'api-errors.collection-next-collection-too-soon',
    collection_user_cannot_be_changed: 'api-errors.collection-user-cannot-be-changed',
    collection_user_not_qualified: 'api-errors.collection-user-not-qualified',
    collection_end_in_the_past: 'api-errors.collection-end-in-the-past',
    collections_maximum_exceeded_explanation: 'api-errors.collections-maximum-exceeded-explanation',
    collections_next_collection_later_than_end:
      'api-errors.collections-next-collection-later-than-end',
    collections_store_maximum_exceeded: 'api-errors.collections-store-maximum-exceeded',
    collections_type_maximum_exceeded: 'api-errors.collections-type-maximum-exceeded',
    collections_maximum_limited_per_day_exceeded:
      'api-errors.collections-maximum-limited-per-day-exceeded',
    fallback_html: 'api-errors.fallback-html',
  }
  const errorPrefixMapping = {
    collections_global_maximum_exceeded: {
      translation: t('common.alerts.error-denied-prefix'),
      prefix: 'error-denied-prefix',
    },
    collection_donation_taken: {
      translation: t('common.alerts.error-denied-prefix'),
      prefix: 'error-denied-prefix',
    },
    collection_end_generic_failure: {
      translation: t('common.alerts.error-prefix'),
      prefix: 'error-prefix',
    },
    collection_next_collection_too_soon: {
      translation: t('common.alerts.error-denied-prefix'),
      prefix: 'error-denied-prefix',
    },
    collection_user_cannot_be_changed: {
      translation: t('common.alerts.error-denied-prefix'),
      prefix: 'error-denied-prefix',
    },
    collection_user_not_qualified: {
      translation: t('common.alerts.error-prefix'),
      prefix: 'error-prefix',
    },
    collections_next_collection_later_than_end: {
      translation: t('common.alerts.error-prefix'),
      prefix: 'error-prefix',
    },
  }

  const displayErrors = errors.map((error) => {
    const prefix = errorPrefixMapping[error]?.prefix
    return t(errorMap[error], {
      defaultValue: t('api_errors.fallback_html'),
      [prefix]: `<b>${errorPrefixMapping[error]?.translation}</b>`,
    })
  })

  if (displayErrors.length === 0) {
    return t('api_errors.fallback_html')
  }
  return displayErrors.join(JOIN_ERROR_SEPARATOR)
}

export const getFieldErrorMessage = ({ t, errorType }) => {
  switch (errorType) {
    case 'required':
      return t('form-field-validation-errors.required')
    case 'selectedOptionRequired':
      return t('form-field-validation-errors.selected-option-required')
    default:
      return t('form-field-validation-errors.generic-field-error')
  }
}

/**
 * @param axiosError Axios error object
 */
export const loggableError = (axiosError) =>
  axiosError?.response || axiosError?.request || axiosError?.message
