import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdOutlineSecurity, MdOutlineStorefront } from 'react-icons/md'
import { useSearchParams } from 'react-router-dom'
import { trackEvent } from 'services/Analytics'
import { modalKeys } from 'config/modals'
import { useModal } from 'hooks/application'
import Button from 'components/common/Button'
import Checkbox from 'components/common/Checkbox'
import {
  ConfirmationModal,
  ModalFooter,
  ModalTitle,
} from 'components/common/Modal/ConfirmationModal'
import styles from './SquadCaptainModal.module.scss'

type Props = {
  storeId: number
  storeName: string
  businessLogo: string
  businessName: string
  toggleSquadCaptain: () => void
}

export const modalKey = (storeId: number) => `${modalKeys.SQUAD_CAPTAIN_AGREEMENT}-${storeId}`

const SquadCaptainModal = ({
  storeId,
  storeName,
  businessLogo,
  businessName,
  toggleSquadCaptain,
}: Props) => {
  const { t } = useTranslation()
  const [confirmCaptain, setConfirmCaptain] = useState(false)
  const { closeModal: closeCurrentModal } = useModal(modalKey(storeId))
  const [, setSearchParams] = useSearchParams()

  const onConfirmationClick = () => {
    toggleSquadCaptain()
    closeCurrentModal()
    setSearchParams(`?captain-success=true`, { preventScrollReset: true })
    trackEvent('SQUAD_CAPTAIN_AGREEMENT_CONFIRMED')
  }

  return (
    <ConfirmationModal modalKey={modalKey(storeId)}>
      <ModalTitle>{t('stores.modal.title')}</ModalTitle>
      <div className={styles.contentWrapper}>
        <div>
          <span className={styles.description}>{t('stores.modal.description')}</span>

          <div className={styles.squadDetails}>
            <img
              className={styles.logo}
              src={businessLogo}
              alt=""
            />
            <MdOutlineStorefront className={styles.houseIcon} />
            {businessName} | {storeName}
          </div>

          <div className={styles.responsibilities}>
            <div className={styles.pointTitle}>
              <MdOutlineSecurity className={styles.shieldIcon} />
              <p>{t('stores.modal.responsibilities.title')}</p>
            </div>
            <div className={styles.responsibilyDescription}>
              <span>{t('stores.modal.responsibilities.description')}</span>
            </div>
            <div>
              <div className={styles.points}>
                <div className={styles.position}>1</div>
                <p>{t('stores.modal.responsibilities.point1.title')}</p>
              </div>
              <div className={styles.pointDescription}>
                <span>{t('stores.modal.responsibilities.point1.description')}</span>
              </div>
            </div>
            <div>
              <div className={styles.points}>
                <div className={styles.position}>2</div>
                <p>{t('stores.modal.responsibilities.point2.title')}</p>
              </div>
              <div className={styles.pointDescription}>
                <span>{t('stores.modal.responsibilities.point2.description')}</span>
              </div>
            </div>
            <div>
              <div className={styles.points}>
                <div className={styles.position}>3</div>
                <p>{t('stores.modal.responsibilities.point3.title')}</p>
              </div>
              <div className={styles.pointDescription}>
                <span>{t('stores.modal.responsibilities.point3.description')}</span>
              </div>
            </div>
          </div>
        </div>

        <Checkbox
          checked={confirmCaptain}
          label={t('stores.modal.agreement')}
          onChange={() => setConfirmCaptain((value) => !value)}
          testId="squad-captain-agreement-checkbox"
        />
      </div>
      <ModalFooter>
        <Button
          secondary
          onClick={() => closeCurrentModal()}
        >
          {t('stores.modal.buttons.cancel')}
        </Button>
        <Button
          disabled={confirmCaptain === false}
          onClick={onConfirmationClick}
        >
          {t('stores.modal.buttons.confirm')}
        </Button>
      </ModalFooter>
    </ConfirmationModal>
  )
}

export default SquadCaptainModal
