import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdInsertLink, MdPictureAsPdf, MdSlideshow, MdSubject } from 'react-icons/md'
import clsx from 'clsx'
import Debug from 'helpers/debug'
import { getDocuments } from 'helpers/documents'
import { appendUniqueIdAndReferrerToTypeformUrl } from 'helpers/typeform'
import styles from './HelpfulInformation.module.scss'

const debug = Debug('components:collections:helpful-information')

const DocumentIcon = {
  picture_as_pdf: <MdPictureAsPdf />,
  slideshow: <MdSlideshow />,
  link: <MdInsertLink />,
  subject: <MdSubject />,
}

const HelpfulInformation = () => {
  const { t } = useTranslation()
  const [documents, setDocuments] = useState([])

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const docs: any[] | any = await getDocuments()
        const enhancedDocuments = docs.map((document) => {
          if (document.url.includes('olio1.typeform')) {
            return {
              ...document,
              url: appendUniqueIdAndReferrerToTypeformUrl(document.url),
            }
          }
          return document
        })

        setDocuments(enhancedDocuments)
      } catch (error) {
        debug(error)
      }
    }
    fetchDocuments()
  }, [])

  const importantDocuments = documents.filter((document) => document.important === true)

  return (
    <div className={clsx('card', styles.root)}>
      <h3 className={styles.title}>
        {t('your-collections.page.index.subtitles.helpful-information')}
      </h3>
      <ul className={styles.documentsList}>
        {importantDocuments.map((importantDoc) => {
          return (
            <li
              key={importantDoc.id}
              className={styles.listItem}
            >
              <span
                className={styles.icon}
                data-testid="icon"
              >
                {DocumentIcon[importantDoc.icon]}
              </span>
              <a
                href={importantDoc.url}
                className={styles.label}
              >
                {importantDoc.label}
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default HelpfulInformation
