import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import { STORES_CLEAR_ERROR } from '@redux/actionTypes'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import fetchStoreCollections from '@redux/thunks/collections/fetchStoreCollections'
import toggleSquadCaptainRole from '@redux/thunks/stores/toggleSquadCaptainRole'
import fetchUser from '@redux/thunks/users/fetchUser'
import Debug from 'helpers/debug'
import { getReportAnIssueUrl } from 'helpers/typeform'
import { getCurrentUserId } from 'helpers/user'
import { useModal } from 'hooks/application'
import { modalKey as squadCaptainModalKey } from 'components/Stores/SquadCaptainModal/SquadCaptainModal'
import Alert from 'components/common/Alert'
import Button from 'components/common/Button'
import Pill from 'components/common/Pill'
import StoreInfo from 'components/common/StoreInfo'
import Rota from 'components/common/StoreRota/Rota'
import UserAvatar from 'components/common/UserAvatar'
import SquadCaptainModal from '../SquadCaptainModal'
import styles from './StoreCard.module.scss'
import { buttonExpandingAnimationConfig, expandingAnimationConfig } from './animations'

const debug = Debug('components:store:store-card')

type Props = {
  storeId: number
  handleExpandClick: (event: React.MouseEvent<HTMLButtonElement>, storeId: number) => void
  handleExpandKeyDown: (event: React.KeyboardEvent<HTMLButtonElement>, storeId: number) => void
  isExpanded: (storeId: number) => boolean
}

const StoreCard = ({ storeId, handleExpandClick, handleExpandKeyDown, isExpanded }: Props) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [isToggleDisabled, setIsToggleDisabled] = useState(false)
  const [, setSearchParams] = useSearchParams()
  const [isAlertVisible, setIsAlertVisible] = useState(false)

  const {
    businessId,
    location: storeLocation,
    squadCaptain,
    name: storeName,
  } = useAppSelector((state) => state.stores.items[storeId] ?? {})

  const { id: squadCaptainId } = squadCaptain || {}
  const isSquadCaptain = useMemo(() => {
    return Number(getCurrentUserId()) === Number(squadCaptainId)
  }, [squadCaptainId])

  const { squadCaptainPhotoUrl, squadCaptainName } = useAppSelector((state) => {
    if (isSquadCaptain) {
      return {
        squadCaptainPhotoUrl: state.users.currentUser.avatar?.small,
        squadCaptainName: state.users.currentUser.name?.firstName,
      }
    }

    return {
      squadCaptainPhotoUrl: state.users.items[squadCaptainId]?.avatar?.small,
      squadCaptainName: state.users.items[squadCaptainId]?.name,
    }
  }, shallowEqual)

  const noSquadCaptain = useMemo(() => {
    return squadCaptainId === null || squadCaptainId === 0
  }, [squadCaptainId])

  const business = useAppSelector((state) => state.stores.businesses[businessId], shallowEqual)
  const businessLogo = business?.logo?.url
  const businessName = business?.name

  const displaySquadCaptainButton = useMemo(() => {
    return noSquadCaptain === true || isSquadCaptain === true
  }, [noSquadCaptain, isSquadCaptain])

  const errors = useAppSelector((state) => state.stores.items[storeId]?.errors)

  const { openModal: openSquadCaptainModal, isOpen: isSquadCaptainModalOpen } = useModal(
    squadCaptainModalKey(storeId),
  )

  useEffect(() => {
    if (storeId !== undefined) {
      dispatch(fetchStoreCollections({ storeId })).catch(debug)
    }
  }, [storeId])

  useEffect(() => {
    if (squadCaptainId !== undefined && noSquadCaptain === false && isSquadCaptain === false) {
      dispatch(fetchUser(squadCaptainId)).catch(debug)
    }
  }, [squadCaptainId])

  const toggleSquadCaptain = async () => {
    setIsToggleDisabled(() => true)
    dispatch(
      toggleSquadCaptainRole({
        isSquadCaptain,
        storeId,
      }),
    ).catch(debug)
    setIsToggleDisabled(() => false)
  }

  const reportAnIssueUrl = useMemo(() => {
    return getReportAnIssueUrl({
      t,
      storeName,
      businessName,
      storeId,
      businessId,
      userId: getCurrentUserId(),
    })
  }, [storeName, businessName, storeId, businessId])

  const [params] = useSearchParams()
  const squadCaptainSuccess = params.get('captain-success') === 'true'

  const displayAlert = () => {
    setIsAlertVisible(true)
    setTimeout(() => {
      setIsAlertVisible(false)
    }, 5000)
  }

  useEffect(() => {
    if (squadCaptainSuccess === true) {
      displayAlert()
      setSearchParams(
        (state) => {
          state.delete('captain-success')
          return state
        },
        { preventScrollReset: true },
      )
    }
  }, [squadCaptainSuccess])

  return (
    <>
      <div className={styles.storeCard}>
        <div>
          <motion.div
            layout="position"
            className={clsx('card', styles.card)}
          >
            <div className={clsx('card-content', styles.cardContent)}>
              {storeLocation !== undefined && businessLogo !== undefined && (
                <StoreInfo
                  logoUrl={businessLogo}
                  location={storeLocation}
                  storeId={storeId}
                />
              )}
              <div className={styles.storeDetails}>
                <div>
                  <div className={styles.pill}>
                    <label>{t('stores.store.store_name')}</label>
                    <Pill>
                      <Pill.Text>
                        <span>🛡️ {storeName}</span>
                      </Pill.Text>
                    </Pill>
                  </div>
                  <div className={styles.pill}>
                    <label>{t('stores.store.squad_captain')}</label>
                    <Pill>
                      {squadCaptainPhotoUrl && (
                        <Pill.Icon>
                          <UserAvatar
                            className="avatar"
                            imageUrl={squadCaptainPhotoUrl}
                          />
                        </Pill.Icon>
                      )}
                      <span>
                        <Pill.Text>{squadCaptainName || t('stores.store.none')}</Pill.Text>
                      </span>
                    </Pill>
                  </div>
                </div>
                <AnimatePresence mode="wait">
                  {isExpanded(storeId) === true && (
                    <>
                      <motion.div
                        className={styles.rota}
                        {...expandingAnimationConfig}
                      >
                        <div className={styles.rotaLabel}>
                          {t('stores.store.squad_captain_collections')}
                        </div>
                        <Rota
                          storeId={storeId}
                          className={styles.storeCardRota}
                        />
                        {errors !== undefined && (
                          <Alert
                            variant="error"
                            className={styles.alert}
                          >
                            {isSquadCaptain === false
                              ? t('stores.store.errors.become_squad_captain_error')
                              : t('stores.store.errors.unassign_squad_captain_error')}
                          </Alert>
                        )}
                        {isAlertVisible && (
                          <Alert
                            variant="status"
                            className={styles.successAlert}
                          >
                            <strong>{t('common.alerts.status')} </strong>
                            {t('stores.store.alert.squad_captain_success')}
                          </Alert>
                        )}
                        {displaySquadCaptainButton === true && (
                          <motion.div
                            className={styles.toggleSquadCaptainRoleButtonWrapper}
                            {...buttonExpandingAnimationConfig}
                          >
                            <Button
                              secondary
                              disabled={isToggleDisabled}
                              onClick={async () => {
                                dispatch({ type: STORES_CLEAR_ERROR, payload: { storeId } })

                                if (noSquadCaptain === true) {
                                  openSquadCaptainModal()
                                } else {
                                  toggleSquadCaptain()
                                }
                              }}
                            >
                              {noSquadCaptain === true &&
                                t('stores.store.buttons.become_squad_captain')}
                              {isSquadCaptain === true &&
                                t('stores.store.buttons.unassign_squad_captain')}
                            </Button>
                          </motion.div>
                        )}
                      </motion.div>
                    </>
                  )}
                </AnimatePresence>
              </div>
              <motion.div
                layout="position"
                initial={false}
                data-testid={`button-wrapper-${storeId}`}
                className={clsx(styles.inlineButtonWrapper, {
                  collapsed: isExpanded(storeId) === false,
                })}
              >
                <Button
                  onClick={(event) => {
                    handleExpandClick(event, storeId)
                    dispatch({ type: STORES_CLEAR_ERROR, payload: { storeId } })
                  }}
                  onKeyDown={(event) => {
                    handleExpandKeyDown(event, storeId)
                  }}
                >
                  {isExpanded(storeId) === true
                    ? t('stores.store.buttons.close_details')
                    : t('stores.store.buttons.more_info')}
                </Button>
                <Button
                  href={reportAnIssueUrl}
                  secondary
                >
                  {t('stores.store.something_wrong_text')}
                </Button>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </div>
      {isSquadCaptainModalOpen && (
        <SquadCaptainModal
          storeId={storeId}
          storeName={storeName}
          businessName={businessName}
          businessLogo={businessLogo}
          toggleSquadCaptain={toggleSquadCaptain}
        />
      )}
    </>
  )
}

export default StoreCard
